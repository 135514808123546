import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { getThemeFactory } from '@ic-theme';
import Image from 'ic/ui-elem/Image';
import UILink from 'ic/ui-elem/Link';
import Button from '@ui-elem/Button/Button';
import browserSvc from 'score/browserSvc';
import Splash from 'deco/Splash';
import * as cls from './Banner.styles';
import cleanKey from 'core/util/cleanKey';
import { NAME as appReducerName } from '@spa-core/store/app/constants';
import { getReducer } from '@spa-core/legacy-adapter/utils';

const th = getThemeFactory('Banner', {
  titleFontWeight: 400 /* DB 600 */,
  contentFontWeight: 200,
  titleLetterSpacing: 'inherit',
  maxTBPadding: '60px',
  maxRLPadding: '80px',
  maxContentFontSize: '24px',
  maxLegalTextFontSize: '16px',
  maxLegalTextBPadding: '10px',
  maxContentFontSizeMobile: '20px',
  titleFontSize: '6vw',
  shadow: 'grey 0px 0px 5px',
});

/**
 * Decamelizes a string with/without a custom separator (underscore by default).
 *
 * @param str String in camelcase
 * @param separator Separator for the new decamelized string.
 */
const decamelize = (str, separator) => {
  const sep = typeof separator === 'undefined' ? '_' : separator;
  // Safe in this context with data from the cms
  return str
    .replace(/([a-z\d])([A-Z])/g, '$1' + sep + '$2') // NOSONAR
    .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, '$1' + sep + '$2') // NOSONAR
    .toLowerCase();
};

const ContentComponent = (p) => {
  const theme = th();
  const styles = { fontWeight: theme.contentFontWeight, textAlign: p.textAlignment };
  if (p.textShadowsInTitle !== 'false') {
    styles.textShadow = theme.shadow;
  }
  return (
    <div
      className={classnames(
        cls.content,
        `color-${p.color ? decamelize(p.color) : 'pale'}`,
        p.contentSmSize ? cls[p.contentSmSize.replace(/_/g, '-')] : cls['size-0'],
        p.contentSize ? cls['md:' + p.contentSize.replace(/_/g, '-')] : cls['md:size-0'],
        'flex',
        cls[`align-${p.textAlignment ? p.textAlignment : 'left'}`]
      )}
      style={styles}
    >
      <div dangerouslySetInnerHTML={{ __html: p.content }} />
    </div>
  );
};

const ContentWithButtonAbove = (p) => {
  return (
    <>
      {p.buttonAboveContent ? (
        <div className="relative e2e-ver-banner-content">{p.content ? <ContentComponent {...p} /> : null}</div>
      ) : null}
    </>
  );
};

const ContentWithOutButtonAbove = (p) => {
  return (
    <>
      {!p.buttonAboveContent ? (
        <div className="relative e2e-ver-banner-content">{p.content ? <ContentComponent {...p} /> : null}</div>
      ) : null}
    </>
  );
};

const ButtonComponent = (p) => {
  const sessionConfig = getReducer(appReducerName).sessionConfig;
  return (
    <>
      {p.buttonText ? (
        <div className={classnames('flex', cls[`align-${p.buttonAlignment ? p.buttonAlignment : 'left'}`], cls.styledButton)}>
          <Button
            fluid={false}
            className={'button m-2'}
            buttonPadding={'md:p-2 p-0'}
            onClick={() => {
              if (!p.url.match(sessionConfig.urlPrefix) && !p.url.match(/^\//)) {
                browserSvc.redirect(p.url);
              } else if (p.url) {
                p.history.push(p.url.replace(sessionConfig.urlPrefix, ''));
              }
            }}
            buttonText={p.buttonText}
          />
        </div>
      ) : null}
    </>
  );
};

const BannerComponent = (p) => {
  const theme = th();
  const titleStyles = { fontWeight: theme.titleFontWeight, letterSpacing: theme.titleLetterSpacing, textAlign: p.textAlignment };
  if (p.textShadowsInTitle !== 'false') {
    titleStyles.textShadow = theme.shadow;
  }
  const buttonStyles = {};
  if (!p.buttonColorType) {
    buttonStyles.background = p.buttonColor;
  }
  const bannerTextStyles = {};
  if (p.textBlockPositionTop) {
    bannerTextStyles.top = p.textBlockPositionTop;
  }
  return (
    <div
      className={classnames(
        cls.banner,
        'banner-component my-1',
        'relative ic-cms-banner',
        p.url ? 'cursor-pointer' : '',
        'e2e-ver-banner-' + p.SSRKey
      )}
    >
      <Splash
        type={p.splashType && p.splashType.length !== 0 ? p.splashType : null}
        text={p.splashText}
        position={p.splashPosition && p.splashPosition.length !== 0 ? p.splashPosition : null}
        className={p.splashClass}
        color={p.splashColor && p.splashColor.length !== 0 ? p.splashColor.toLowerCase() : null}
      />

      <div>
        <div className={'w-full'}>
          <Image
            src={p.imgSrc}
            stretch={true}
            srcset={`${p.imgSmSrc ? p.imgSmSrc + ' 600w, ' : ''}${p.imgSrc}`}
            srcsetOnHover={`${p.imgSmSrcOnHover ? p.imgSmSrcOnHover + ' 600w, ' : ''}${p.imgSrcOnHover ? p.imgSrcOnHover : ''}`}
            altText={p.imgSmAltText}
            srcsetSizes={'(max-width: 600px) 600px'}
            altTextOnHover={p.imgSmAltTextOnHover}
          />
          <div
            className={classnames('absolute w-full ', `color-${p.color ? decamelize(p.color) : 'pale'}`, cls['banner-text'])}
            style={bannerTextStyles}
          >
            {p.contentAboveHeading === 'true' ? <ContentWithOutButtonAbove {...p} /> : null}
            {p.contentAboveHeading === 'true' && p.buttonAboveContent ? (
              <ButtonComponent {...p} buttonStyles={{ buttonStyles }} />
            ) : null}
            {p.contentAboveHeading === 'true' ? <ContentWithButtonAbove {...p} /> : null}
            {p.title ? (
              <div
                dangerouslySetInnerHTML={{ __html: p.title }}
                className={classnames(
                  p.headlineSmSize ? cls[p.headlineSmSize.replace(/_/g, '-')] : cls['size-0-title'],
                  p.headlineSize ? cls['md:' + p.headlineSize.replace(/_/g, '-')] : cls['md:size-0-title'],
                  'flex m-0',
                  cls.title,
                  cls[`align-${p.textAlignment ? p.textAlignment : 'left'}`]
                )}
                style={titleStyles}
              />
            ) : null}
            {p.contentAboveHeading === 'false' && p.buttonAboveContent ? (
              <ButtonComponent {...p} buttonStyles={{ buttonStyles }} />
            ) : null}
            {p.contentAboveHeading === 'false' ? <ContentWithOutButtonAbove {...p} /> : null}
            {p.contentAboveHeading === 'false' && !p.buttonAboveContent ? (
              <div className={'ml-6'}>
                <ButtonComponent {...p} buttonStyles={{ buttonStyles }} />
              </div>
            ) : null}
            {p.contentAboveHeading === 'false' ? <ContentWithButtonAbove {...p} /> : null}
            {p.contentAboveHeading === 'true' && !p.buttonAboveContent ? (
              <ButtonComponent {...p} buttonStyles={{ buttonStyles }} />
            ) : null}
          </div>
          {p.legalText ? (
            <div
              className={classnames(
                'absolute w-full flex',
                `color-${p.color ? decamelize(p.color) : 'pale'}`,
                cls.legalText,
                cls[`align-${p.legalTextAlignment ? p.legalTextAlignment : 'left'}`]
              )}
              style={{ fontSize: theme.maxLegalTextFontSize }}
            >
              <div
                className={classnames('w-full', `text-${p.legalTextAlignment ? p.legalTextAlignment : 'left'}`)}
                dangerouslySetInnerHTML={{ __html: p.legalText }}
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

class Banner extends React.Component {
  static get propTypes() {
    return {
      title: PropTypes.string,
      content: PropTypes.string,
      textAlignment: PropTypes.string,
      imgSrc: PropTypes.string,
      imgSmSrc: PropTypes.string,
      imgAltText: PropTypes.string,
      imgSmAltText: PropTypes.string,
      imgSrcOnHover: PropTypes.string,
      imgSmSrcOnHover: PropTypes.string,
      imgAltTextOnHover: PropTypes.string,
      imgSmAltTextOnHover: PropTypes.string,
      url: PropTypes.string,
      legalText: PropTypes.string,
      buttonText: PropTypes.string,
      buttonColor: PropTypes.string,
      buttonColorType: PropTypes.string,
      width: PropTypes.string,
      mode: PropTypes.string,
      headlineSize: PropTypes.string,
      headlineSmSize: PropTypes.string,
      contentSize: PropTypes.string,
      contentSmSize: PropTypes.string,
      textBlockPositionTop: PropTypes.string,
      history: PropTypes.shape({
        push: PropTypes.func.isRequired,
      }),
      color: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'panel', 'error', 'success', 'info', 'pale', '']),
      buttonAboveContent: PropTypes.any,
      customImageMargin: PropTypes.string,
      responsiveWidthOnMobile: PropTypes.string,
      textShadowsInTitle: PropTypes.any,
      splashType: PropTypes.oneOf(['circle', 'speech_bubble', 'tag_circle', '']),
      splashText: PropTypes.string,
      splashPosition: PropTypes.oneOf(['top_right', 'top_left', 'bottom_right', 'bottom_left', '']),
      splashClass: PropTypes.string,
      splashColor: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'panel', 'error', 'success', 'info', 'pale', 'puff', '']),
    };
  }

  render() {
    const theme = th();
    const url = this.props.url;
    let buttonAboveContent = this.props.buttonAboveContent === 'true' || this.props.buttonAboveContent === true;
    if (!this.props.content) {
      buttonAboveContent = true;
    }
    let widthParsed = '';
    if (this.props.width) {
      widthParsed = decodeURIComponent(this.props.width).replace(/_/g, '-');
    }
    if (widthParsed === 'ic-col-12') {
      widthParsed = 'w-full';
    }

    let mobileWidthParsed = ''; // defines the width of banner in mobile
    if (this.props.responsiveWidthOnMobile) {
      mobileWidthParsed = decodeURIComponent(this.props.responsiveWidthOnMobile).replace(/_/g, '-');
    }
    if (mobileWidthParsed === 'ic-col-12') {
      mobileWidthParsed = 'w-full';
    }

    if (!this.props.imgSrc) return null;

    return (
      <div
        data-ssr-key={cleanKey(this.props.SSRKey)}
        className={classnames(
          'complete-banner ',
          mobileWidthParsed ? cls['mobile-width-' + mobileWidthParsed] : '',
          widthParsed ? cls['ipad-desktop-width-' + widthParsed] : '',
          cls.completeBanner,
          'smartEditComponent'
        )}
        data-smartedit-component-id={this.props.SSRKey}
        data-smartedit-component-uuid={this.props.elementUid}
        data-smartedit-catalog-version-uuid={this.props.contentSlotCatalogVersion}
        data-smartedit-component-type={this.props.componentParentType}
      >
        {url ? (
          <UILink to={url} SSRKey={this.props.SSRKey} target={this.props.target}>
            <BannerComponent {...this.props} buttonAboveContent={buttonAboveContent} />
          </UILink>
        ) : (
          <BannerComponent {...this.props} />
        )}
      </div>
    );
  }
}

export default withRouter(Banner);
